/* You can add global styles to this file, and also import other style files */
@use 'tailwindcss/base';
@use 'tailwindcss/components';
@use 'tailwindcss/utilities';

.reverse-svg {
  path {
    fill: white !important;
    // fill-opacity: 1 !important;
  }
}

// override hacks
select {
  -moz-appearance: none;
  -webkit-appearance: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &::-ms-expand {
    display: none;
  }
}

@layer base {
  html,
  body {
    @apply text-16 font-sans leading-24;
  }

  .prose {
    h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote {
      @apply mt-32;

      &:first-child {
        @apply mt-0;
      }
    }
  }

  .h1, .h2, .h3, .h4, .h5, .h6,
  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4,
  .prose h5,
  .prose h6  {
    @apply leading-none font-bold tracking-wide;
  }

  .h1,
  .prose h1 {
      @apply text-104;
  }

  .h2,
  .prose h2 {
      @apply text-60;
  }

  .h3,
  .prose h3 {
      @apply text-40;
  }

  .h4,
  .prose h4 {
      @apply text-24;
  }

  .h5,
  .prose h5 {
      @apply text-20 uppercase;
  }

  .lede,
  .blockquote,
  .prose blockquote {
    @apply text-40 font-normal leading-none;
  }

  // .cite,
  // .prose cite {
  //     @apply block text-16 leading-24 font-normal font-poppins;
  // }

  .strong,
  .prose strong,
  .prose b {
    @apply font-bold;
  }

  .small,
  .prose small {
      @apply text-12 leading-normal;
  }

  .ul,
  .prose ul {
      @apply pl-20 list-disc;
  }

  .ol,
  .prose ol {
      @apply pl-20 list-decimal;
  }

  .a,
  .prose a {
    @apply underline font-bold;
  }
}
